var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showLogo)?_c('div',{staticClass:"logoPreview"},[(
      (_vm.landing.branding.logo && _vm.page === 'home' && !_vm.landing.branding.logo_preview) ||
      (_vm.landing.branding.logo &&
        _vm.page === 'advanced'&& !_vm.landing.branding.logo_preview) || (_vm.landing.branding.logo && _vm.page === 'menuStyles' && !_vm.landing.branding.logo_preview) && !_vm.landing.branding.header_preview
    )?_c('div',{staticClass:"canvas__logo",style:(("justify-content:" + _vm.positionLogo + ";height:" + (_vm.landing.branding.logo_size.height) + "px;margin-bottom:" + (_vm.landing.branding.title[_vm.landing.current_lang] ? '0' : '20px') + ";position:relative;width: 100%;padding:0 15px;margin-top:" + (_vm.landing.branding.header_image || _vm.landing.branding.header_preview ? '-50px' : '20px') + ";"))},[_c('img',{style:(("border-radius:" + (_vm.landing.branding.logo_size.radius) + "px;height:" + (_vm.landing.branding.logo_size.height) + "px;width:" + (_vm.landing.branding.logo_size.width) + "px;")),attrs:{"src":_vm.landing.branding.logo,"alt":""}})]):_vm._e(),(
    !_vm.landing.branding.logo_preview&&
      _vm.landing.branding.logo &&
      _vm.page !== 'home' &&
      _vm.$store.state.listing.listingGroup === null &&
      _vm.showLogo &&
      _vm.landing.advanced.global.active_logo_inside &&
      _vm.page !== 'advanced' &&
      _vm.page !== 'order' &&
      _vm.page !== 'lining' &&
      _vm.page !== 'intro' &&
      _vm.page !== 'underConstructor' &&
      _vm.page !== 'topMenu' &&
      _vm.page !== 'lining' &&
      _vm.page !== 'order' &&
      _vm.page !== 'profile'&& _vm.page !== 'menuStyles'
    )?_c('div',{staticClass:"canvas__logo",style:(("padding:0 55px;margin-bottom:20px;justify-content:" + _vm.positionLogo + ";position:relative;width:100%;margin-top:" + (_vm.landing.branding.header_image &&
      _vm.landing.advanced.global.active_header_inside || _vm.landing.branding.header_preview
        ? '-50px'
        : '20px') + ";"))},[_c('img',{style:(("border-radius:" + (_vm.landing.branding.logo_size.radius) + "px;height:" + (_vm.landing.branding.logo_size.height) + "px;width:" + (_vm.landing.branding.logo_size.width) + "px")),attrs:{"src":_vm.landing.branding.logo,"alt":""}})]):_vm._e(),(_vm.landing.branding.logo_preview)?_c('div',{staticClass:"logo_preview",style:(("display:flex;justify-content:" + _vm.positionLogo + ";height:" + (_vm.landing.branding.logo_size.height) + "px;margin-bottom:" + (_vm.landing.branding.title[_vm.landing.current_lang] ? '0' : '20px') + ";position:relative;width: 100%;padding:0 55px;margin-top:" + (_vm.landing.branding.header_image|| _vm.landing.branding.header_preview ? '-50px' : '20px') + ";"))},[_c('div',{staticClass:"logo_preview_img",style:(("border-radius:" + (_vm.landing.branding.logo_size.radius) + "px;height:" + (_vm.landing.branding.logo_size.height) + "px;width:" + (_vm.landing.branding.logo_size.width) + "px;overflow:hidden;"))})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }